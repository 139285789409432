import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, Badge, UncontrolledAlert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Header, SidebarNav, Footer, PageContent, Avatar, Chat, PageAlert, Page } from '../vibe';
import Logo from '../assets/images/golsticket_logo.png';
import nav from '../_nav';
import navDep from '../_nav_dept';
import routes from '../views';
import Login from '../views/pages/Login'
import ContextProviders from '../vibe/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../vibe/helpers/handleTabAccessibility';
import AppProvider from '../contextApp'
import { getCookie } from '../vibe/helpers/AuthenticatedComponent'

const MOBILE_SIZE = 992;

export default class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
      sesion: false,
      user: {}
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
    this.isLogged();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleSideCollapse = () => {
    this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };

  isLogged = async() =>{
    const session = await getCookie('STORAGE_KEY_GOLSAGE');    
    if(!session){
      this.setState({
        sesion: false
      })
      return;
    }
    const usr = JSON.parse(getCookie('USER_GOLSAGE'));
    this.setState({
      sesion: true,
      user: usr
    })
  }


  render() {
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';
    return (
      <AppProvider>
        <ContextProviders>
          <div className={`app ${sidebarCollapsedClass}`}>
            <PageAlert />
            <div className="app-body">
              <SidebarNav
                nav={ this.state.user.dept !=null ? navDep : nav }
                logo={Logo}
                logoText=""
                isSidebarCollapsed={sidebarCollapsed}
                toggleSidebar={this.toggleSideCollapse}
                {...this.props}
              />
              <Page>
                <Header
                  toggleSidebar={this.toggleSideCollapse}
                  isSidebarCollapsed={sidebarCollapsed}
                  routes={routes}
                  {...this.props}
                >
                  <HeaderNav />
                </Header>
                {
                  this.state.sesion ?
                    null
                  : 
                    <UncontrolledAlert color="danger">
                      Por favor inicia sesión
                    </UncontrolledAlert>
                }
                <PageContent>
                  <Switch>
                    {routes.map((page, key) => (
                      <Route path={page.path} component={this.state.sesion ? page.component : Login } key={key}  />
                    ))}
                      <Redirect from="/" to="/login" />
                  </Switch>
                </PageContent>
              </Page>
            </div>
            <Footer>
              <span>Golsticket | Golsystems de México</span>
            </Footer>
          </div>
        </ContextProviders>
      </AppProvider>
    );
  }
}

function HeaderNav() {
  const cerrarSesion = async() =>{
    document.cookie = "STORAGE_KEY_GOLSAGE=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location = '/login';
  }
  return (
    <React.Fragment>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <Avatar size="small" color="blue" initials="JS" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={()=>{ window.location = '/mi-cuenta'; }} >Mi cuenta</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={()=>cerrarSesion()} >Cerrar sesión</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
}
