export default {
  top: [
    // {
    //   name: 'Dashboard',
    //   url: '/home',
    //   icon: 'Home',
    // },
    {
      name: 'Trámites',
      icon: 'Layers',
      children: [
        {
          name: 'Nuevo',
          url: '/solicitud',
        },
        {
          name: 'Seguimiento',
          url: '/seguimiento',
        },
        {
          name: 'Calendario 1',
          url: '/calendario?calendario=1',
        },
        {
          name: 'Calendario 2',
          url: '/calendario2?calendario=2',
        }
      ],
    },
    {
      name: 'Reportes',
      icon: 'File',
      children: [
        // {
        //   name: 'Trámites',
        //   url: '/reporte-tramites',
        // },
        {
          name: 'Citas',
          url: 'reportes-cita',
        }
      ],
    },
    {
      name: 'Administrador',
      icon: 'Package',
      children: [
        {
          name: 'Programar fechas',
          url: '/programar-fechas',
        },
        // {
        //   name: 'Lista atendidos',
        //   url: 'lista-atendidos',
        // },
        // {
        //   name: 'Usuarios',
        //   url: 'usuarios',
        // },
        {
          name: 'Requisitos trámites',
          url: 'tramites',
        },
      ],
    },
    {
      divider: true,
    },
    {
      name: 'Subir atendidos',
      url: '/subir-atendidos',
      icon: 'Cloud'
    },
    {
      name: 'Subir para Entrega',
      url: '/subir-entrega',
      icon: 'Cloud'
    },
  ],
  bottom: [
    // {
    //   name: 'Configuración',
    //   url: '/configuracion',
    //   icon: 'Box',
    // },
  ],
};
