import React, { useState, useContext, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { NavLink } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, UncontrolledAlert, Col, Row, Card, CardBody } from 'reactstrap';
import { insertDataJson, getData, updateItem } from '../../vibe/helpers/helpers'
import { getCookie } from '../../vibe/helpers/AuthenticatedComponent'
import { Select } from '@material-ui/core';

const Tramites = ()=>{
  const [datosform, setDatosForm] = useState({
    'desc_tramite':'',
    'tiempo_tramite_min':'',
    'num_calendario': 1,
    'status': 0
  })
  const {
    desc_tramite,
    tiempo_tramite_min,
    num_calendario
  } = datosform;
  const [tramites, setTramites] = useState([]);
  const [actualizar, setActualizar] = useState(false);
  const [mensaje, actualizaMensaje] = useState({
    status: false,
    message:'',
    label: 'danger'
  });

  const actualizaState = e =>{
    setDatosForm({
      ...datosform,
      [e.target.name] : e.target.value
    })
  }

  const submitForm = async e =>{
    e.preventDefault();
    if(desc_tramite === '' || tiempo_tramite_min === '' || tiempo_tramite_min === 0 ){
      actualizaMensaje({
        status:true,
        label: "warning",
        message: "Todos los campos son requeridos"
      })      
      return;
    }
    const usr = JSON.parse(getCookie('USER_GOLSAGE'));
    actualizaMensaje({
      status:true,
      label: "primary",
      message: "Guardando...."
    })
    const ruta = 'tramite';
    if(actualizar){
      const send2 = await updateItem(ruta+'/'+datosform.id,datosform);
      console.log(send2)
      if(send2.code === 200){
        actualizaMensaje({
          status:true,
          label: "success",
          message: "Se ha actualizado correctamente"
        })
        setActualizar(false);
        setDatosForm({
          'desc_tramite':'',
          'tiempo_tramite_min':'',
          'num_calendario': 1,
          'status': 0
        })
        getTramites();
        return;
      }
    }else{
      const send = await insertDataJson(ruta,datosform);
      if(send.code === 201){
        actualizaMensaje({
          status:true,
          label: "success",
          message: "Se ha guardado correctamente"
        })
        getTramites();
        return;
      }
    }
    actualizaMensaje({
      status:true,
      label: "danger",
      message: "Algo ha ocurrido y no se guardaron los datos"
    })
  }

  const getTramites = async()=>{
    const send = await getData('tramite');
    console.log(send);
    if(send.data){
      setTramites(send.data)
      return;
    }
  }

  const actualizaTramite = async (id) =>{
    const ruta = 'tramite';
    const send = await getData(ruta+'/'+id);
    if(send.code === 201){
      setDatosForm(send.data);
      setActualizar(true);
      return;
    }
  }

  useEffect(()=>{
    getTramites();
  },[]);
  

  const [columnastablas, setColumnasTablas] = useState(
    [
      { 
        title: "Trámite", 
        render: (row)=><NavLink to={`/requisitos-tramites/?id=${row.id}`}>{ row.desc_tramite }</NavLink>
      },
      { title: "Tiempo (min)", field: "tiempo_tramite_min" },
      {
        title: "",
        render: (row)=>< a href="#" onClick={ ()=>actualizaTramite(row.id) } className="text text-warning">Editar</a>
      }
    ]
  );

  return(
  <Row>
    <Col md={{ size: 8, offset: 2 }}>
      <Card>
      <CardBody>
      <Form
        onSubmit={submitForm}
      >
        {
          mensaje.status ?
            <UncontrolledAlert color={mensaje.label}>
              {mensaje.message}
            </UncontrolledAlert>
          : null
        }
        <FormGroup>
          <Label for="desc_tramite">Nombre del trámite</Label>
          <Input 
            type="text" 
            name="desc_tramite" 
            id="desc_tramite" 
            onChange = {actualizaState}
            value = { desc_tramite }
            />
        </FormGroup>
        <FormGroup>
          <Label for="tiempo_tramite_min">Tiempo en min</Label>
          <Input 
            type="number" 
            name="tiempo_tramite_min" 
            id="tiempo_tramite_min"
            value = { tiempo_tramite_min }
            onChange = {actualizaState}
            required
            />
        </FormGroup>
        {/* <FormGroup>
          <Label for="desc_tramite">Administrar en</Label>
          <Input 
            type="select" 
            name="num_calendario" 
            id="num_calendario"
            value = { num_calendario }
            onChange = {actualizaState}
            >
            <option value="1" >Calendario 1</option>
            <option value="2" >Calendario 2</option>
          </Input>
        </FormGroup> */}
        {
          actualizar ?
            <Button color="warning" type="buttom">Actualizar</Button>
          :
            <Button color="primary" type="buttom">Guardar</Button>
        }
      </Form>
      </CardBody>
      </Card>
      <Card>
        <CardBody>
          <MaterialTable
            options={{
              headerStyle:{fontSize:'12px'},
              bodyStyle:{fontSize:'12px'},
              pageSize: 10
            }}
            title="Fechas programadas"
            columns={columnastablas}
            data={tramites}
            onRowSelected
          />
        </CardBody>
      </Card>
    </Col>
  </Row>
  )
}

export default Tramites