import React, { useState, useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, UncontrolledAlert, Col, Row, Card, CardBody } from 'reactstrap';
import { insertDataJson } from '../../vibe/helpers/helpers'
import { getCookie } from '../../vibe/helpers/AuthenticatedComponent'

const ListaAtendidos = ()=>{
  const [solicitud, setSolicitud] = useState({
    email:'',
    password: ''
  })
  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });

  const actualizaState = e =>{
    setSolicitud({
      ...solicitud,
      [e.target.name] : e.target.value
    })
  }

  const submitForm = async e =>{
    actualizaMensaje({
      status:false
    })
    e.preventDefault();
    const ruta = 'solicitud';
    const send = await insertDataJson(ruta,solicitud);
    if(send.code === 201){
    }

    actualizaMensaje({
      status:true,
      label: "danger",
      message: "Algo ha ocurrido y no se guardaron los datos"
    })
  }

  return(
  <Row>
    <Col md={{ size: 8, offset: 2 }}>
      <Card>
      <CardBody>
      </CardBody>
      </Card>
    </Col>
  </Row>
  )
}

export default ListaAtendidos