import React, { useState, useContext, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Button, Form, FormGroup, Label, Input, UncontrolledAlert, Col, Row, Card, CardBody } from 'reactstrap';
import { insertDataJson, getData, deleteItem } from '../../vibe/helpers/helpers'
import { getCookie } from '../../vibe/helpers/AuthenticatedComponent'
import 'moment/locale/es-mx';

const ProgramarFechas = ()=>{
  const [fechasprogamadas, setFechasProgramadas] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [datosform, setDatosForm] = useState({
    'fecha_inicio':'',
    'fecha_fin': '',
    'hora_inicio': '',
    'hora_fin': '',
    'status': 0,
    'departamento_id': 0
  })
  const {
    fecha_inicio,
    fecha_fin,
    hora_inicio,
    hora_fin,
    departamento_id
  } = datosform;

  const [mensaje, actualizaMensaje] = useState({
    status: false,
    message:'',
    label: 'danger'
  });

  const actualizaState = e =>{
    setDatosForm({
      ...datosform,
      [e.target.name] : e.target.value
    })
  }

  const submitForm = async e =>{
    e.preventDefault();
    if(departamento_id === 0 || fecha_inicio === '' || fecha_fin === '' || hora_inicio === '' || hora_fin === ''){
      actualizaMensaje({
        status:true,
        label: "warning",
        message: "Todos los campos son requeridos"
      })      
      return;
    }
    const usr = JSON.parse(getCookie('USER_GOLSAGE'));
    actualizaMensaje({
      status:true,
      label: "primary",
      message: "Guardando...."
    })

    const ruta = 'fecha-programada';
    const send = await insertDataJson(ruta,datosform);
    if(send.code === 201){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha guardado correctamente"
      })
      getFechasProgramadas();
      return;
    }

    actualizaMensaje({
      status:true,
      label: "danger",
      message: "Algo ha ocurrido y no se guardaron los datos"
    })
  }

  const eliminarFecha = async (id) =>{
    const ruta = 'fecha-programada';
    const send = await deleteItem(ruta+'/'+id);
    if(send.code === 200){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha eliminado correctamente"
      })
      getFechasProgramadas();
      return;
      return;
    }
  }

  const getFechasProgramadas = async()=>{
    const send = await getData('fecha-programada');
    console.log(send);
    if(send.data){
      setFechasProgramadas(send.data)
      return;
    }
  }
  const getDepartamento = async()=>{
    const send = await getData('departamento');
    console.log(send);
    if(send.data){
      setDepartamentos(send.data)
      return;
    }
  }
  
  useEffect(()=>{
    getFechasProgramadas();
    getDepartamento();
  },[]);
  

  const [columnastablas, setColumnasTablas] = useState(
    [
      { title: "Fecha Inicio", field: "fecha_inicio" },
      { title: "Fecha Fin", field: "fecha_fin" },
      { title: "Hora Inicio", field: "hora_inicio" },
      { title: "Hora Fin", field: "hora_fin" },
      {
        title: "",
        render: (row)=>< a href="#" onClick={ ()=>eliminarFecha(row.id) } className="text text-danger">Eliminar</a>
      }
    ]
  );

  return(
  <Row>
    <Col md={{ size: 8, offset: 2 }}>
      <Card>
      <CardBody>
      <Form
        onSubmit={submitForm}
      >
        {
          mensaje.status ?
            <UncontrolledAlert color={mensaje.label}>
              {mensaje.message}
            </UncontrolledAlert>
          : null
        }
        <FormGroup>
          <Label for="departamento_id">Departamento</Label>
          <Input 
            type="select" 
            name="departamento_id" 
            id="departamento_id"
            onChange = {actualizaState}
            >
            <option value="0" >Seleccion una opción </option>
            {
              departamentos ? 
                departamentos.map(item=>(
                  <option value={item.id} >{ item.desc_departamento }</option>
                ))
              : null
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="fecha_inicio">Fecha inicio</Label>
          <Input 
            type="date" 
            name="fecha_inicio" 
            id="fecha_inicio" 
            onChange = {actualizaState}
            />
        </FormGroup>
        <FormGroup>
          <Label for="fecha_fin">Fecha fin</Label>
          <Input 
            type="date" 
            name="fecha_fin" 
            id="fecha_fin" 
            onChange = {actualizaState}
            />
        </FormGroup>
        <FormGroup>
          <Label for="hora_inicio">Hora inicio</Label>
          <Input 
            type="time" 
            name="hora_inicio" 
            id="hora_inicio" 
            onChange = {actualizaState}
            />
        </FormGroup>
        <FormGroup>
          <Label for="hora_fin">Hora fin</Label>
          <Input 
            type="time" 
            name="hora_fin" 
            id="hora_fin" 
            onChange = {actualizaState}
            />
        </FormGroup>
        <Button color="primary" type="buttom">Guardar</Button>
      </Form>
      </CardBody>
      </Card>
      <Card>
        <CardBody>
          <MaterialTable
            options={{
              headerStyle:{fontSize:'12px'},
              bodyStyle:{fontSize:'12px'},
              pageSize: 10
            }}
            title="Fechas programadas"
            columns={columnastablas}
            data={fechasprogamadas}
            onRowSelected
          />
        </CardBody>
      </Card>
    </Col>
  </Row>
  )
}

export default ProgramarFechas