import Login from './pages/Login';
import Solicitud from './pages/Solicitud';
import Seguimiento from './pages/Seguimiento';
import Calendario from './pages/Calendario'
import DetalleSolicitud from './pages/DetalleSolicitud'
import ReporteTramites from './pages/ReporteTramites';
import ReporteCitas from './pages/ReporteCitas';
import ProgramarFechas from './pages/ProgramarFechas';
import ListaAtendidos from './pages/ListaAtendidos';
import Usuarios from './pages/Usuarios';
import Tramites from './pages/Tramites';
import RequisitosTramites from './pages/RequisitosTramites';
import SubirAtendido from './pages/SubirAtendido';
import SubirEntrega from './pages/SubirParaEntrega'
import Configuracion from './pages/Configuracion';
import MiCuenta from './pages/MiCuenta';

import Dashboard from './pages/Dashboard';
import Buttons from './elements/Buttons';
import Alerts from './elements/Alerts';
import Grid from './elements/Grid';
import Typography from './elements/Typography';
import Cards from './elements/Cards';
import Tabs from './elements/Tabs';
import Tables from './elements/Tables';
import Breadcrumbs from './elements/Breadcrumbs';
import Forms from './elements/Forms';
import Loaders from './elements/Loaders';
import Avatars from './elements/Avatars';
import Invoice from './pages/Invoice';
import Analytics from './pages/Analytics';
import CmsPage from './pages/Cms';
import Widgets from './pages/Widgets';
import BlankPage from './pages/BlankPage';
import SubNav from './pages/SubNav';
import Feed from './pages/Feed';
import Modals from './elements/Modals';
import ProgressBars from './elements/ProgressBars';
import PaginationPage from './elements/Pagination';
import ErrorPage from './pages/404';

// See React Router documentation for details: https://reacttraining.com/react-router/web/api/Route
const pageList = [
  {
    name: 'Login',
    path: '/login',
    component: Login,
  },
  {
    name: 'Nuevo Trámite',
    path: '/solicitud',
    component: Solicitud,
  },
  {
    name: 'Seguimiento',
    path: '/seguimiento',
    component: Seguimiento,
  },
  {
    name: 'Detalle Solicitud',
    path: '/detalle',
    component: DetalleSolicitud,
  },
  {
    name: 'Calendario 1',
    path: '/calendario',
    component: Calendario,
  },
  {
    name: 'Calendario 2',
    path: '/calendario2',
    component: Calendario,
  },
  {
    name: 'Calendario Extraordinario',
    path: '/calendario3',
    component: Calendario,
  },
  {
    name: 'Reporte Tramites',
    path: '/reporte-tramites',
    component: ReporteTramites,
  },
  {
    name: 'Reporte Citas',
    path: '/reportes-cita',
    component: ReporteCitas,
  },
  {
    name: 'Programar Fechas',
    path: '/programar-fechas',
    component: ProgramarFechas,
  },
  {
    name: 'Lista Atendidos',
    path: '/lista-atendidos',
    component: ListaAtendidos,
  },
  {
    name: 'Usuarios',
    path: '/usuarios',
    component: Usuarios,
  },
  {
    name: 'Trámites',
    path: '/tramites',
    component: Tramites,
  },
  {
    name: 'Requisitos de Trámites',
    path: '/requisitos-tramites',
    component: RequisitosTramites,
  },
  {
    name: 'Subir Atendidos',
    path: '/subir-atendidos',
    component: SubirAtendido,
  },
  {
    name: 'Subir para Entrega',
    path: '/subir-entrega',
    component: SubirEntrega,
  },
  {
    name: 'Configuración',
    path: '/configuracion',
    component: Configuracion,
  },
  {
    name: 'Dashboard',
    path: '/home',
    component: Dashboard,
  },
  {
    name: 'Mi Cuenta',
    path: '/mi-cuenta',
    component: MiCuenta,
  },
  {
    name: 'Buttons',
    path: '/elements/buttons',
    component: Buttons,
  },
  {
    name: 'Alerts',
    path: '/elements/alerts',
    component: Alerts,
  },
  {
    name: 'Grid',
    path: '/elements/grid',
    component: Grid,
  },
  {
    name: 'Typography',
    path: '/elements/typography',
    component: Typography,
  },
  {
    name: 'Cards',
    path: '/elements/cards',
    component: Cards,
  },
  {
    name: 'Tabs',
    path: '/elements/tabs',
    component: Tabs,
  },
  {
    name: 'Tables',
    path: '/elements/tables',
    component: Tables,
  },
  {
    name: 'Progress Bars',
    path: '/elements/progressbars',
    component: ProgressBars,
  },
  {
    name: 'Pagination',
    path: '/elements/pagination',
    component: PaginationPage,
  },
  {
    name: 'Modals',
    path: '/elements/modals',
    component: Modals,
  },
  {
    name: 'Breadcrumbs',
    path: '/elements/breadcrumbs',
    component: Breadcrumbs,
  },
  {
    name: 'Forms',
    path: '/elements/forms',
    component: Forms,
  },
  {
    name: 'Loaders',
    path: '/elements/loaders',
    component: Loaders,
  },
  {
    name: 'Avatars',
    path: '/elements/avatars',
    component: Avatars,
  },
  {
    name: 'Blank',
    path: '/pages/blank',
    component: BlankPage,
  },
  {
    name: 'Sub Navigation',
    path: '/pages/subnav',
    component: SubNav,
  },
  {
    name: '404',
    path: '/pages/404',
    component: ErrorPage,
  },
  {
    name: 'Analytics',
    path: '/apps/analytics',
    component: Analytics,
  },
  {
    name: 'Activity Feed',
    path: '/apps/feed',
    component: Feed,
  },
  {
    name: 'Invoice',
    path: '/apps/invoice',
    component: Invoice,
  },
  {
    name: 'CMS',
    path: '/apps/cms',
    component: CmsPage,
  },
  {
    name: 'Widgets',
    path: '/widgets',
    component: Widgets,
  },
];

export default pageList;
