import React, {createContext, useState} from 'react'

export const AppContext = createContext();

const AppProvider = (props) =>{
  const [usuario, setUsuario] = useState({
    id: 0,
    email: '',
    nombre: ''
  })
  return(
    <AppContext.Provider
      value={{
        usuario,
        setUsuario
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider
