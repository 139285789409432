import React, { useState, useContext, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, UncontrolledAlert, Col, Row, Card, CardBody } from 'reactstrap';
import { insertDataJson, getData } from '../../vibe/helpers/helpers'
import { getCookie } from '../../vibe/helpers/AuthenticatedComponent'

const Solicitud = ()=>{
  const [tipotramites, setTipoTramites] = useState([]);
  const [tramites, setTramites] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [estatuses, setEstatuses] = useState([]);
  const [datosform, setDatosForm] = useState({
    'folio':'',
    'descripcion_solicitud':'',
    'status': 0,
    'tipo_tramite_id': 4,
    'tramite_id': 0,
    'user_id': 0,
    'departamento_id': 0,
    'estatus_id': 1,
    'nombre_completo': '',
    'matricula': '',
    'fecha_solicitud': ''
  })
  const [mensaje, actualizaMensaje] = useState({
    status: false,
    message:'',
    label: 'danger'
  });

  const actualizaState = e =>{
    setDatosForm({
      ...datosform,
      [e.target.name] : e.target.value
    })
  }

  const submitForm = async e =>{
    const usr = JSON.parse(getCookie('USER_GOLSAGE'));
    actualizaMensaje({
      status:true,
      label: "primary",
      message: "Guardando...."
    })
    e.preventDefault();
    let datos = {
      'descripcion_solicitud': datosform.descripcion_solicitud,
      'status': datosform.status,
      'tipo_tramite_id': datosform.tipo_tramite_id,
      'tramite_id': datosform.tramite_id,
      'user_id': datosform.user_id,
      'departamento_id': datosform.departamento_id,
      'estatus_id': datosform.estatus_id,
      'nombre_completo': datosform.nombre_completo,
      'matricula': datosform.matricula,
      'user_id': usr.id
    }
    const ruta = 'solicitud';
    const send = await insertDataJson(ruta,datos);
    if(send.code === 201){
      actualizaMensaje({
        status:true,
        label: "success",
        message: "Se ha guardado correctamente"
      })
      window.location = '/detalle/?id='+send.data.id;
      return;
    }

    actualizaMensaje({
      status:true,
      label: "danger",
      message: "Algo ha ocurrido y no se guardaron los datos"
    })
  }

  const getTramite = async()=>{
    const send = await getData('tramite');
    console.log(send);
    if(send.data){
      setTramites(send.data)
      return;
    }
  }
  const getDepartamento = async()=>{
    const send = await getData('departamento');
    console.log(send);
    if(send.data){
      setDepartamentos(send.data)
      return;
    }
  }


  useEffect(()=>{
    //getTipo();
    getTramite();
    getDepartamento();
    //getEstatus();
  },[]);
  

  return(
  <Row>
    <Col md={{ size: 8, offset: 2 }}>
      <Card>
      <CardBody>
      <Form
        onSubmit={submitForm}
      >
        <FormGroup>
          <Label for="tramite_id">Trámite</Label>
          <Input 
            type="select" 
            name="tramite_id" 
            id="tramite_id"
            onChange = {actualizaState}
            >
            <option value="0" >Seleccion una opción </option>
            {
              tramites ? 
                tramites.map(item=>(
                  <option value={item.id} >{ item.desc_tramite }</option>
                ))
              : null
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="departamento_id">Departamento</Label>
          <Input 
            type="select" 
            name="departamento_id" 
            id="departamento_id"
            onChange = {actualizaState}
            >
            <option value="0" >Seleccion una opción </option>
            {
              departamentos ? 
                departamentos.map(item=>(
                  <option value={item.id} >{ item.desc_departamento }</option>
                ))
              : null
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="name">Nombre del alumno</Label>
          <Input 
            type="text" 
            name="nombre_completo" 
            id="nombre_completo" 
            onChange = {actualizaState}
            />
        </FormGroup>
        <FormGroup>
          <Label for="matricula">Matricula</Label>
          <Input 
            type="text" 
            name="matricula" 
            id="matricula" 
            onChange = {actualizaState}
            />
        </FormGroup>
        <FormGroup>
          <Label for="descripcion_solicitud">Teléfono</Label>
          <Input 
            type="text" 
            name="descripcion_solicitud" 
            id="descripcion_solicitud" 
            onChange = {actualizaState}
            />
        </FormGroup>
        {/* <FormGroup>
          <Label for="estatus_id">Estatus</Label>
          <Input 
            type="select" 
            name="estatus_id" 
            id="estatus_id"
            onChange = {actualizaState}
            >
            <option value="0" >Seleccion una opción </option>
            {
              estatuses ? 
              estatuses.map(item=>(
                <option value={item.id} >{ item.desc_estatus }</option>
                ))
              : null
            }
          </Input>
        </FormGroup> */}
        <Button color="primary" type="buttom">Guardar</Button>
      </Form>
      </CardBody>
      </Card>
    </Col>
  </Row>
  )
}

export default Solicitud